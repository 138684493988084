import gql from "graphql-tag";

export const vehiclesDetailsFields = `
    id
    contactId
    make
    model
    subModel
    year
    vin
    name
    licensePlate
    licensePlateState
    odometer
    CCA
    odometerHistory{
        mileage
        updatedAt
        updatedBy
        include
    }
    vehicleTechnicalInfo{
        wheelTorque
        frontRotorsDiscardThicknessInches
        rearRotorsDiscardThicknessInches
        hasDrumBrakes
        parkingBrake
    }
    externalServiceHistory{
      type
      source
      odometer
      displayDate
      text
      displayText
      updatedAt
    }
    notes
    inactive
    createdAt
    updatedAt
`;

export const GET_VEHICLES = gql`
    query getVehicles($contactId: ID!) {
        getVehicles(contactId: $contactId) {
            ${vehiclesDetailsFields}
        }
    }
    `;

export const GET_VEHICLE = gql`
    query getVehicle($id: ID!) {
        getVehicle(id: $id) {
            ${vehiclesDetailsFields}
        }
    }
`;