import React, { Fragment } from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from "@material-ui/core";
import { Field } from "formik";
import ServiceRow from "./ServiceRow";
import { ProductSelectionOption } from "./ProductRow";
import { PossibleEjiService } from "../../generated/nest-graphql";
import { has } from "ramda";
import classNames from "classnames";

type ServiceTableProps = {
  partsStores: any;
  ejiType?: string;
  calculatePossibleEJIPriceInfo: any;
  parts: ProductSelectionOption[];
  serviceList: PossibleEjiService[];
  taxable: boolean;
};

export const ServicesTable: React.FC<ServiceTableProps> = ({
  partsStores,
  ejiType,
  calculatePossibleEJIPriceInfo,
  taxable,
  parts,
  serviceList,
}) => {
  return (
    <TableContainer>
      <Field name="services">
        {({ field: { value: services = [] }, meta: { error } }) => {
          const allServiceWithErrors = false && error?.length >= services.length && error?.every(error => error?.items?.length);
          return (
            <Table key="services">
              <TableHead>
                <TableRow>
                  <TableCell className="w-1/12">Quote to Customer</TableCell>
                  <TableCell></TableCell>
                  <TableCell className={classNames(allServiceWithErrors ? "w-6/12" :"w-5/12")}>Service</TableCell>
                  {!allServiceWithErrors && <TableCell className="w-1/6">Customer Price</TableCell>}
                  <TableCell className={classNames(allServiceWithErrors ? "w-3/12" :"w-1/6")}>Technician Base Pay</TableCell>
                  <TableCell className="w-1/12">Delete</TableCell>
                  <TableCell className="w-1/12" />
                </TableRow>
              </TableHead>
              <TableBody>
                <Fragment>
                  {services.map((row, serviceIdx) => (
                    <ServiceRow
                      key={serviceIdx}
                      row={row}
                      taxable={taxable}
                      serviceIdx={serviceIdx}
                      partsStores={partsStores}
                      ejiType={ejiType}
                      allServiceWithErrors={allServiceWithErrors}
                      calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
                      parts={parts}
                      serviceList={serviceList}
                    />
                  ))}
                </Fragment>
              </TableBody>
            </Table>
          );
        }}
      </Field>
    </TableContainer>
  );
};
